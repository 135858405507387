import React, { useLayoutEffect, useState } from 'react'
import styles from "./download.module.scss"
import { Link } from 'react-router-dom';
import appStore from "../../assets/images/appStore.png"
import playStore from "../../assets/images/playStore.png"
import logo from "../../assets/images/logo.png"
import webImg from "../../assets/images/web.png"
import { useNavigate, useSearchParams } from "react-router-dom";
import 'react-alice-carousel/lib/alice-carousel.css';



const Download = () => {
  const [queries] = useSearchParams();
  const navigate = useNavigate()
  const [width, setWidth] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0)
  console.log("scrollHeight", scrollHeight)
  const [animate, setAnimate] = useState(false)
  
  useLayoutEffect(() => {
      function updateSize() {
          setWidth(window.innerWidth)
      }
      updateSize();
      window.addEventListener('resize', updateSize);
      return () => window.removeEventListener('resize', updateSize);
  }, []);

  const height = "100rem"
  const handleRouteClick = () => {
      navigate("/")
  }

  return (
    <>
    <div className={styles.download_cont}>
      <div style={{ cursor: "pointer", position: 'absolute', top: "1rem", left: "1rem" }} onClick={handleRouteClick} ><img className={styles.logo} src={logo} alt="logo" />
      </div>
      <div className={styles.download_subCont}>
        <div className={styles.download_header}>
          Download Now
        </div>

        <div className={styles.download_btn}>


          <div className={styles.img_cont}>
            <Link id="ios_link" className={styles.applink} to="https://apps.apple.com/in/app/vyka-games/id6502700024" >
              <img className={styles.d_img} src={appStore} alt="appStore" />
              <div className={styles.content_name}>Play on IOS</div>
            </Link>
          </div>

          {/* <div className={styles.img_cont}>
            <Link id="android_link" className={styles.applink} to="https://gamingprod.s3.ap-south-1.amazonaws.com/apk/vykagames-V1.1.apk" >
              <img className={styles.d_img} src={playStore} alt="playStore" />
              <div className={styles.content_name}>Play on App</div>
            </Link>

          </div> */}

          <div className={styles.img_cont}>
            <Link id="website_link" className={styles.applink} to="https://games.vykagames.com/" >
              <div className={styles.web_cont}>
                <img className={styles.d_img1} src={webImg} alt="web" />
              </div>
              <div className={styles.content_name}>Play on Web</div>
            </Link>

          </div>

        </div>
      </div>
    </div>
</>
  )
}

export default Download
