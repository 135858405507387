import React, { useEffect } from 'react';
import styles from "../thirteencardsrummy/thirteenCardsRummy.module.scss"

const RummyRulesguide = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      
  return (
    <div className={styles.thirRummy_cont}>
      <h3>How to Play Rummy Card Game: Rummy Rules Guide</h3>

      <div className={styles.content}>
      <p>Rummy is a classic card game played with two standard decks of 52 cards, along with two jokers.</p>
      </div>

      <div className={styles.content}>
      <h3>Objective of Rummy Card Game</h3>
      <p>The objective of rummy is to group your cards into valid combinations of sets and sequences. To begin, the dealer shuffles the cards and deals 13 cards to each player. The remaining cards form the draw pile.</p>
      </div>

      <div className={styles.content}>
      <h3>Cards</h3>
      <p>A standard deck of 52 cards is used, with Ace, 2, 3, 4, 5, 6, 7, 8, 9, 10, Jack, Queen, and King in each suit. </p>
      </div>

      <div className={styles.content}>
      <h3>Joker</h3>
      <p>One random card is chosen as the wild joker, and all other cards of the same rank in different suits become wild jokers.</p>
      </div>

      <div className={styles.content}>
      <h3>Declaration</h3>
      <p>A player can declare rummy by forming at least two sequences, with one being a pure sequence. They must show their cards to confirm the declaration.</p>
      </div>

      <div className={styles.content}>
      <h3>Types of Melds</h3>
      </div>

      <div className={styles.content}>
      <h3>Sequence</h3>
      <p>A sequence is three or more cards of the same suit in consecutive order.</p>
      </div>

      <div className={styles.content}>
      <h3>Pure Sequence</h3>
      <p>A pure sequence is a sequence without any jokers.</p>
      </div>

      <div className={styles.content}>
      <h3>Impure Sequence</h3>
      <p>An impure sequence contains one or more jokers.</p>
      </div>

      <div className={styles.content}>
      <h3>Set</h3>
      <p>A set is three or more cards of the same rank, regardless of suit.</p>
      </div>

      <div className={styles.content}>
      <h3>How to Form Melds</h3>
      </div>

      <div className={styles.content}>
      <h3>Using Jokers and Wild Cards</h3>
      <p>Strategically use jokers and wild cards to complete sequences and sets.</p>
      </div>

      <div className={styles.content}>
      <h3>Valid Declarations</h3>
      <p>A valid declaration requires at least two sequences, including one pure sequence.</p>
      </div>

      <div className={styles.content}>
      <h3>Gameplay Example</h3>
      <p>Let's say you are dealt the following cards:</p>
      <ul>
        <li>3♠ 4♠ 5♠ (Sequence)</li>
        <li>7♦ 7♣ 7♠ (Set)</li>
        <li>Q♣ Q♠ (Set)</li>
        <li>Joker🃏 (Wild Card)</li>
      </ul>
      <p>You need to form at least two sequences, one being pure. Here's how you can play:</p>

      <ol>
        <li>
          <h4> Sequence Formation:</h4>
          <ul>
            <li>Use the joker as 6♠ to complete the sequence: 3♠ 4♠ 5♠ 6♠.</li>
          </ul>
        </li>
        <li>
          <h4>Set Formation:</h4>
          <ul>
            <li>Use the joker as 7♥ to complete the set: 7♦ 7♣ 7♠ 7♥.</li>
            <li>Form another set: Q♣ Q♠ Q♥.</li>
          </ul>
        </li>
        <li>
          <h4>Valid Declaration:</h4>
          <ul>
            <li>Arrange your cards into two sequences (one pure) and two sets.</li>
            <li>Declare rummy by showing your melds to other players.</li>
          </ul>
        </li>
      </ol>
      </div>

      <div className={styles.content}>
      <h3>Points Calculation</h3>
      <p>Points are scored based on the cards remaining with players at the end. The player with the lowest score wins.</p>
      </div>

      <div className={styles.content}>
      <h3>Winning Calculation Example</h3>
      <p>Suppose in Points Rummy:</p>
      <ul>
        <li>Opponent 1 has 20 points.</li>
        <li>Opponent 2 has 30 points.</li>
        <li>Opponent 3 has 15 points.</li>
        <li>You have 0 points.</li>
      </ul>
      <p>Your winning calculation would be:</p>
      <p>Winning Cash=(20+30+15)× Rupee Value of Point-Platform Fees</p>
      </div>

      <div className={styles.content}>
      <h3>Dos & Don'ts</h3>
      </div>

      <div className={styles.content}>
      <h3>Dos:</h3>
      <ul>
        <li>Prioritize forming a pure sequence early.</li>
        <li>Observe opponents' moves.</li>
        <li>Strategically use jokers and wild cards. </li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Don'ts:</h3>
      <ul>
        <li>Hold onto high-value cards for too long.</li>
        <li>Wait indefinitely for specific cards.</li>
        <li>Waste jokers on pure sequences unnecessarily.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <p>Mastering rummy involves strategic play, meld formation, and effective card management. Use jokers and wild cards wisely to create valid combinations and aim for the lowest score to win the game.</p>
      </div>
    </div>
  );
}

export default RummyRulesguide;
