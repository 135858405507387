import React, { useEffect, useState } from 'react';
import styles from "./resonsibleGaming.module.scss"
import logo from "../../assets/images/logo.png"
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet';

const ResponsibleGaming = () => {
    const [seoData, setSeoData] = useState<any>({})
    const navigate = useNavigate()
    const [queries] = useSearchParams();
    const [isEmbed, setEmbed] = useState(queries.get('embed'))
    const handleRouteClick = () => {
        if (isEmbed === null) {
            navigate("/")
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const seodatalocal = JSON.parse(localStorage.getItem('seoData') || '{}');
        setSeoData(seodatalocal);
    }, []);

    return (

        <>
            <Helmet>
                <title>{ "Responsible Gaming"}</title>
                <meta name="description" content={seoData?.data?.[9]?.description || "Default Description"} />
                <meta name="keywords" content={seoData?.data?.[9]?.keyword?.[9] || "Default Keywords"} />
            </Helmet>
            <div className={`${styles.termsandconditions} ${isEmbed && styles.termsandconditions1}`}>
                {
                    isEmbed ? "" : <div style={{ cursor: "pointer" }} onClick={handleRouteClick} ><img style={{ width: "80px" }} src={logo} alt="logo" /></div>
                }

                <header className={`${styles.termsConditions} ${isEmbed && styles.termsConditions1}`}>Responsible Gaming</header>
                <div className={styles.content}>
                    <p>
                        As a responsible gaming company, we prioritize the well-being of our players and advocate for responsible play. We maintain a vigilant watch over player activities and promptly intervene if any signs of compulsive behavior emerge. To ensure a positive gaming experience, we implement various precautionary measures:
                    </p>

                    <p>
                        Individuals under the age of 18 are strictly prohibited from creating Vyka Rummy accounts or participating in games on our Website/Mobile App.
                    </p>

                    <p>
                        Continuous monitoring of player activities occurs around the clock, aided by an anti-fraud algorithm designed to identify unfair practices, including collusion.
                    </p>

                    <p>
                        Player data is securely stored using advanced encryption methods to safeguard confidentiality.
                        Our Website/Mobile App incorporates features that restrict cash deposit amounts.
                    </p>
                    <p>
                        To discourage irresponsible gaming habits, we enforce cash deposit limits on the platform, preventing players from exceeding reasonable spending thresholds.
                    </p>

                    <p>
                        KYC verification is conducted to ensure player protection and enhance game security.
                        We actively promote responsible gaming practices and may issue periodic notifications or alerts to players spending excessive amounts of time on Vyka Rummy, advising them to take breaks as necessary.
                    </p>

                    <p>
                        Players have the option to utilize our self-exclusion feature, enabling them to voluntarily abstain from Vyka Rummy games for specified durations ranging from 48 hours to 6 months. Once a self-exclusion request is submitted and processed, players are restricted from accessing our games for the designated period, with no option for reversal or modification. Additionally, creation of another Vyka Rummy account is prohibited during this time. Any remaining account balance will be retained until the player's return, unless a withdrawal request is initiated.
                    </p>


                </div>
            </div>
        </>

    );
}

export default ResponsibleGaming;
