import React, { useEffect, useState } from "react";
import styles from "./gstpolicy.module.scss"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import logo from "../../assets/images/logo.png"
import { useNavigate, useSearchParams } from "react-router-dom";
import gstimg from "../../assets/images/gstbanner.webp"
import { Helmet } from 'react-helmet';
export default function GSTPolicy() {
    const [seoData, setSeoData] = useState<any>({})
    const navigate = useNavigate()
    const [queries] = useSearchParams();
    const [isEmbed, setEmbed] = useState(queries.get('embed'))
    const handleRouteClick = () => {
        if (isEmbed === null) {
            navigate("/")
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const seodatalocal = JSON.parse(localStorage.getItem('seoData') || '{}');
        setSeoData(seodatalocal);
    }, []);
    return (
        <>
            
            <Helmet>
                <title>{seoData?.data?.[6].title || "Privacy Policy"}</title>
                <meta name="description" content={seoData?.data?.[6]?.description || "Default Description"} />
                <meta name="keywords" content={seoData?.data?.[6]?.keyword?.[0] || "Default Content"} />
            </Helmet>
           <section className={`${styles.gst_cont} ${isEmbed && styles.gst_cont1}`}>
           <div className={styles.banner_gstcont}>
                <div style={{ cursor: "pointer", position: 'absolute', top: "1rem", left: "1rem" }} onClick={handleRouteClick} ><img className={styles.logo} src={logo} alt="logo" />
                </div>
                <div className={styles.banner_cont}>
                   {/* <div> dfsdf </div> */}
                   <LazyLoadImage src={gstimg} alt="gstimg" className={styles.bnr_img}  />
                </div>
            </div>
            <div className={styles.gst_subCont}>
                    <div className={styles.header}>GST Policy</div>
                   <div className={styles.cont1}>
                    {/* <div>image</div> */}
                    <p className={styles.content_cont}>Online Gaming is subject to GST (Goods and Services Tax) rates applied to online skill-based games. Effective from 1st October 2023, the new GST policy will be applicable for all rummy games in India.</p>
                   </div>
                   <div className={styles.cont2}>
                    <p className={styles.heading}>GST In VYKA Rummy</p>
                    <p className={styles.content_cont}>In accordance with the government's new GST Policy, a GST of 28% will be applicable on all deposits made on VYKA Rummy. To reduce the burden of the new GST charges in rummy card games, VYKA Rummy will give you 100% GST as cashback on every deposit by using promo code <span>NOGST</span>.</p>
                    <p className={styles.content_cont}>
                        <span className={styles.topic}>For Example: </span> If you deposit ₹100 in your VYKA Rummy account, you'll receive ₹78.12 after a 28% GST deduction of ₹21.88. By using the promo code NOGST at the time of the deposit, the 100% of the GST amount will be instantly credited to your VYKA Rummy account as a cash bonus making your game balance as ₹100.
                    </p>
                    <p className={styles.content_cont}>Please refer to the Terms and Conditions for up-to-date details about GST.</p>
                   </div>
                   <div className={styles.cont3}>
                    <p className={styles.heading}>GST And Cash Bonus Calculation</p>
                    <div className={styles.calc_cont}>
                        <div className={styles.calc_field}>
                            <p className={styles.content_cont}>Deposit Amount</p>
                            <p className={styles.content_cont}>₹100</p>
                        </div>
                        <div className={styles.calc_field}>
                            <p className={styles.content_cont}>Amount Credited to Deposit Balance</p>
                            <p className={styles.content_cont}>₹78.12</p>
                        </div>
                        <div className={styles.calc_field}>
                            <p className={styles.content_cont}>GST Paid to Government (28% of Deposit Balance)</p>
                            <p className={styles.content_cont}>₹21.88</p>
                        </div>
                        <div className={styles.calc_field}>
                            <p className={styles.content_cont}>100% GST Cash Bonus</p>
                            <p className={styles.content_cont}>₹21.88</p>
                        </div>
                        <div className={styles.calc_field}>
                            <p className={styles.content_cont}>Amount Credited in Wallet After Transaction (Deposit Balance + 100% GST Cash Bonus)</p>
                            <p className={styles.content_cont}>₹100</p>
                        </div>
                    </div>
                   </div>
                   </div>
           </section>
        </>
    );
}