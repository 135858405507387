import React, { useEffect, useState } from 'react';
import styles from "../winmoneygameapp/wmga.module.scss"
import { Helmet } from 'react-helmet';
const AboutLudo = () => {
    const [seoData, setSeoData] = useState<any>({})
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const seodatalocal = JSON.parse(localStorage.getItem('seoData') || '{}');
        setSeoData(seodatalocal);
    }, []);
    return (

        <>
         <Helmet>
                <title>{seoData?.data?.[3].title || "Privacy Policy"}</title>
                <meta name="description" content={seoData?.data?.[3]?.description || "Default Description"} />
                <meta name="keywords" content={seoData?.data?.[3]?.keyword?.[0] || "Default Content"} />
            </Helmet>
        <div className={styles.thirRummy_cont}>
            <h3>About Ludo Online</h3>

            <div className={styles.content}>
                <p>Ludo is a board game that has been around for centuries. The game is thought to have originated in India, and it has been popular in many cultures since then. Ludo is a simple game that can be played with two to four players. The objective of the game is to get all of your pieces around the board before your opponents do.</p>
                <p>There are many different variations of ludo, and the rules can vary depending on the region where the game is played. In this blog post, we will explore some of the most popular variants of ludo from around the world.</p>
            </div>

            <div className={styles.content}>
                <p>
                    Vyka Rummy  is a notable platform offering a diverse array of games and challenges where users can compete against others for real cash prizes. With an intuitive user interface and seamless gameplay experience, Vyka Rummy provides an engaging environment for users to showcase their skills and pursue monetary rewards.
                </p>
            </div>

            <div className={styles.content}>
                <p>
                    You may already know that Ludo was invented in India. Because 25 was the maximum amount of points a player could receive in the game, Ludo is known in India as "Pachisi," which translates to the number "25." The Mughal rulers created it as a strategic and pleasure game. Many people mistakenly believe it to be a game of chance, but because the dice's numbers are entirely random, it's actually more of a strategy and preparation game.</p>
            </div>

            <div className={styles.content}>
                <p>
                    Ludo today is a standard board game for two to four players. Each player has their own coloured home with four pawns in it. A player must move all of their pawns to their home and earn the most points in order to win. Online ludo games are played faster and have fewer turns available. This increases the enjoyment and excitement of the game.</p>
            </div>

            <div className={styles.content}>
                <p>How many types of ludo are there?</p>
            </div>

            <div className={styles.content}>
                <p>There are many ludo game types, some of which are Indian ludo, Pachisi, Chinese Ludo, Thai Ludo, etc.</p>
            </div>

            <div className={styles.content}>
                <h3>History Of Ludo</h3>
                <p>Ludo history is as interesting as the game itself which has been around for centuries. The game is thought to have originated in India, and it is believed to be based on an ancient Indian game called Pachisi. Pachisi is thought to have been played by the Mughal emperors of India.</p>
                <p>Ludo arrived in the West in the 19th century, and it quickly became a popular game. The first known mention of Ludo in the West was in 1891 when it was mentioned in an English magazine. The game soon became popular in England and America.</p>
                <p>
                    Ludo is a simple game that can be played by anyone. The goal of the game is to get all of your pieces around the board and into your home base before your opponents do.</p>
                <p>The game is played with two or four players, and each player has four pieces (pawns). Players take turns rolling a die and moving their piece around the board according to the number they rolled. If a player rolls a 6, they get to roll again. If a piece lands on a square occupied by another player’s piece, then that piece is sent back to its starting position. The first player to get all four of their pieces home wins the game!</p>
            </div>

            <div className={styles.content}>
                <h3>Variations Of Ludo</h3>
                <p>Ludo is a board game that can be played with two to four players. The object of the game is to get all of your pieces around the board and into your home base before your opponents do.</p>
                <p>There are many different Ludo types, each with its own set of rules. Here are some of the most popular ludo variations:</p>

                <ul>
                <li>
                    Chinese Ludo: This variant is played on a square board with four corners. Each player has four pieces, which start in their respective corners. The goal is to get all four pieces around the board and into the home base.</li>
                <li>Korean Ludo: This variant is played on an octagonal board with eight corners. Each player has three pieces, which start in their respective corners. The goal is to get all three pieces around the board and into the home base.</li>
                <li>Thai Ludo: This variant is played on a rectangular board with four corner pockets. Each player has four pieces, which start in their respective corner pockets. The goal is to get all four pieces around the board and into the home base.</li>
                <li>Ludo Dice Game: This variant is played with dice instead of pawns. The goal is to be the first player to reach 100 points.
                    Whichever way you choose to play Ludo, it’s sure to be a fun game for all!</li>
                </ul>
                
            </div>

        </div>
        </>
    );
}

export default AboutLudo;
