import React, { useEffect, useState } from 'react';
import styles from "./terms&conditions.module.scss"
import logo from "../../assets/images/logo.png"
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
const TermsAndConditions = () => {
    const [seoData, setSeoData] = useState<any>({})
    const navigate = useNavigate()
    const [queries] = useSearchParams();
    const [isEmbed, setEmbed] = useState(queries.get('embed'))
    const handleRouteClick = () => {
        if (isEmbed === null) {
            navigate("/")
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const seodatalocal = JSON.parse(localStorage.getItem('seoData') || '{}');
        setSeoData(seodatalocal);
    }, []);

    return (
        
        <>
         <Helmet>
                <title>{seoData?.data?.[9].title || "Privacy Policy"}</title>
                <meta name="description" content={seoData?.data?.[9]?.description || "Default Description"} />
                <meta name="keywords" content={seoData?.data?.[9]?.keyword?.[9] || "Default Keywords"} />
            </Helmet>
        <div className={`${styles.termsandconditions} ${isEmbed && styles.termsandconditions1}`}>
            {
                isEmbed ? "" : <div style={{ cursor: "pointer" }} onClick={handleRouteClick} ><img style={{ width: "80px" }} src={logo} alt="logo" /></div>
            }
           
            <header className={`${styles.termsConditions} ${isEmbed && styles.termsConditions1}`}>Terms and Conditions</header>
            <div className={styles.content}>
                <h2>Terms of Service </h2>
                <p>
                    All services provided by Sree Vyka Games Private Limited, including
                    the vykagames.com website and Website/Mobile app, as well as the games
                    and services accessible through them, collectively referred to as
                    "Services," are subject to the following Terms of Service. Sree Vyka
                    Games Private Limited, a company incorporated under the Companies Act
                    1956, with its corporate office at 4-6-137/10, SAI NAGAR, Allwyn
                    Colony Road, Hyderabad, Medchal Malkajgiri, Telangana, 500072, and/or
                    its affiliates, subsidiaries, associates, and partners, hereinafter
                    referred to as "We" or "Us", operate these services. By accessing or
                    using our Website/Mobile App and the Services, you agree to these
                    Terms of Service.
                </p>

                <p>
                    You, as the user, player, or visitor to our Website/Mobile App,
                    acknowledge that you have read and understood these Terms of Service
                    and consent to be bound by them. You also confirm compliance with all
                    requirements outlined herein, possess the legal capacity and authority
                    to bind yourself to these Terms of Service, and hold the necessary
                    authorization to use devices associated with your user account.
                </p>

                <p>
                    Your usage of the Services signifies your understanding and acceptance
                    of these Terms of Service, including any future amendments, which may
                    be posted on the Website/Mobile Application. Continued use of the
                    Services after the publication of amended Terms indicates your
                    acceptance of those changes. If you do not agree with any provision of
                    these Terms of Service, please refrain from accessing or using the
                    Services or the Website/Mobile Application.
                </p>

                <p>
                    Upon registering on Vyka Rummy, you agree to receive notifications via
                    SMS/RCS, unless you expressly opt out. Additionally, you agree to
                    abide by our Terms of Service, Privacy Policy, and Game Rules. It is
                    noted that the invalidity or unenforceability of any single term in
                    our Terms of Service will not affect the enforceability or validity of
                    the remaining terms.
                </p>

                <p>
                    Should you choose not to adhere to our Terms of Service or any future
                    modifications, you have the option to discontinue your use of our
                    Services and all features of our Website/Mobile App.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Agreement</h2>
                <p>
                    Please ensure thorough comprehension of our Terms of Service before
                    proceeding to become a user of Vyka Rummy. All clauses within Vyka
                    Rummy’s Terms of Service are clearly outlined here and/or accessible
                    through related links provided below. Prior to becoming a user of Vyka
                    Rummy, it is imperative that you read and fully understand these Terms
                    of Service. By choosing to use our Services or Vyka Rummy, you are
                    unequivocally bound by these Terms of Service. Additionally, you must
                    agree to adhere to the Terms of Service, Privacy Policy, Fair Play
                    Policy, Responsible Gaming Policy, Game Cancellation and Settlement
                    Policy, Game Rules, and all policies delineated within the Vyka Rummy
                    Website/Mobile App.
                </p>

                <p>
                    We retain the right to modify or amend our Terms at any time without
                    prior notification by publishing the amended Terms on the
                    Website/Mobile app. Vyka Rummy may, at its discretion, notify users of
                    any changes or modifications via email to the registered email address
                    or through notifications within the user’s account. Users have the
                    option to express non-acceptance of the modified Terms of Service,
                    rules, regulations, and terms of use outlined herein or provided by
                    Vyka Rummy within the specified timeframe. Failure to exercise these
                    options within the prescribed timeframe or continued access to the
                    Website/Mobile App after notification of changes will constitute
                    acceptance of the modified Terms, rules, regulations, and terms of
                    use.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Description of Our Rummy Games Services</h2>

                <p>
                    We offer our users/players an exceptional opportunity to engage in
                    multiplayer rummy games around the clock. Utilizing our seamless
                    software and IT services, you can participate in online rummy, a game
                    of skill, alongside fellow rummy enthusiasts on Vyka Rummy.
                </p>

                <p>
                    Our Services are designed solely for the purpose of providing
                    entertainment through rummy gameplay. Vyka Rummy bears no
                    responsibility or liability for any instances of betting or gambling
                    occurring outside our Website/Mobile App. Additionally, in adherence
                    to state laws, specifically in Andhra Pradesh, Assam, Sikkim,
                    Nagaland, Meghalaya, and Telangana, commonly referred to as
                    "Restricted States" or "Restricted Territories," cash rummy games are
                    prohibited. Thus, Vyka Rummy does not host any games or tournaments
                    involving cash prizes or winnings within these territories. Residents
                    or individuals accessing Vyka Rummy from these Restricted States are
                    strictly prohibited from participating in cash games or tournaments.
                    If you reside in or access the Vyka Rummy Website/Mobile App from any
                    of the Restricted States, kindly refrain from engaging in any game or
                    tournament involving real money.
                </p>

                <p>
                    We are obligated by law to deduct tax at source (TDS) from Net
                    Winnings, calculated as Withdrawals minus Deposits minus FY Opening
                    Balance. Per the latest tax regulations issued by the Government of
                    India, 30% TDS will be deducted from the Net Winnings included in the
                    withdrawal amount either at the time of withdrawal or at the end of
                    the financial year. PAN (permanent account number) verification is
                    mandatory for withdrawals. Furthermore, KYC verification is required
                    for cumulative deposits of ₹50,000 or more, or upon the first
                    withdrawal, whichever occurs first. KYC verification entails
                    validating your ID, address, and Website/Mobile number. We retain the
                    right to periodically verify your PAN and/or KYC documents (ID and
                    address) and to nullify or revoke any prizes awarded to you if
                    discrepancies are discovered during our verification process.
                </p>
            </div>

            <div className={styles.content}>
                <h2>GST Compliance and Deductions </h2>
                <p>
                    In accordance with recent amendments to the Central Goods and Services
                    Tax Act, 2017, Integrated Goods and Services Tax Act, 2017, the State
                    Goods and Services Tax Act, 2017, and associated Rules and
                    Notifications, collectively referred to as "GST Laws," pertaining to
                    the taxation of online gaming, Vyka Rummy is required to levy GST at a
                    rate of 28% (28% of the deposit amount) on deposits made by
                    players/users for participation on Vyka Rummy. Both Vyka Rummy and the
                    player/user mutually acknowledge that the deposited amount in the
                    player/user's Vyka Rummy account will be deemed inclusive of GST.
                    Consequently, Vyka Rummy will deduct the GST portion from the
                    player's/user's account to remit to the Government.
                </p>
                <p>
                    In the event that Vyka Rummy, at its sole and absolute discretion,
                    opts to refund an inadvertently deposited amount by a player/user, the
                    refund will be processed after deducting the applicable GST as per GST
                    Laws. No full refund will be provided, and any refund is subject to
                    Vyka Rummy's absolute discretion.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Game Variants</h2>
                <p>
                    On Vyka Rummy, you can play 13-Card Pool Rummy, 13-Card Deals Rummy,
                    13-Card Points Rummy, and Rummy Tournaments. You can play both free
                    and cash rummy games on Vyka Rummy.{" "}
                </p>
            </div>

            <div className={styles.content}>
                <h2>Eligibility to Play Our Games</h2>
                <p>
                    We strictly prohibit individuals under the age of 18 from accessing or
                    playing our games. Should you facilitate or permit anyone under 18
                    years old to access our Website/Mobile App or Services using your
                    computer, Website/Mobile device, or any other internet-enabled device
                    or connection, whether borrowed, owned, or leased, you assume full
                    responsibility and accept liability for any consequences that may
                    arise, including adverse outcomes. Neither Vyka Rummy nor any
                    third-party content provider, nor their respective agents, shall be
                    held accountable for any direct or indirect, incidental, or
                    consequential injuries or damages resulting from the use or inability
                    to use our Website/Mobile App by the user, regardless of prior
                    warnings or advisories regarding such possibilities
                </p>
                <p>
                    We retain the right to request proof of your age at any time to ensure
                    compliance with our policy prohibiting minors from using our Services
                    or playing our games. Additionally, we reserve the right to ban any
                    individual from playing our games if they provide false age
                    verification or if there is suspicion of underage participation.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Creation and Operation of a User Account</h2>
                <p>
                    To use our Services, you are required to register with us on our
                    Website/Mobile App using a strong password. After registration, a
                    username will be automatically assigned to you. For account
                    verification, you will be required to provide more personal
                    information (as may be necessary) when you opt to play our cash
                    games/tournaments. Before depositing cash or playing cash games, you
                    must ensure that your password is strong and also includes capital
                    letters, special characters, and digits.
                </p>

                <p>
                    For security purposes, you may be required to verify the authenticity
                    of the information you provide at the time of creating an account or
                    any time after that, which may require additional proof and documents.
                    If you fail to provide the proof and documents requested, we reserve
                    the right to suspend or terminate your Vyka Rummy account and
                    registration. Please note that we are not obligated to cross-check or
                    verify your information and hence we do not take any responsibility
                    for any consequences or outcomes that may result from your providing
                    false, incorrect, incomplete, concealed, misleading, or irrelevant
                    information. Therefore, you are required to provide us with complete
                    and authentic information to the best of your knowledge to play our
                    online games or use our Services. Also, you should not be a
                    politically exposed person or convicted of any crime. You should not
                    be on the UN sanction list/ work for the UN sanction list.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Geography, Jurisdiction, Payments and Players’ Funds</h2>
                <p>
                    Before engaging in cash transactions or participating in cash rummy
                    games/tournaments on our Website/Mobile App, it is imperative to
                    confirm the legality of such activities in your jurisdiction. If you
                    are not legally authorized to conduct transactions in Indian rupees
                    via banking channels in India, or if playing cash rummy
                    games/tournaments online is prohibited in your jurisdiction, you are
                    prohibited from participating in cash games/tournaments on Vyka Rummy.
                    Violation of this policy constitutes a breach of our Terms of Service,
                    resulting in immediate locking of your Vyka Rummy account and
                    forfeiture of any prizes or refunds associated with such cash
                    games/tournaments.
                </p>

                <p>
                    Furthermore, users residing in or accessing the Website/Mobile App
                    from Restricted States are prohibited from participating in any games
                    or tournaments offering monetary prizes. It is imperative to adhere to
                    the laws of these states and refrain from using Vyka Rummy's Services
                    for cash transactions or gameplay while in or visiting Restricted
                    States.
                </p>

                <p>
                    Accessing the Vyka Rummy Website/Mobile App from a Restricted
                    Territory or outside India using IP or GPS masking software, such as
                    Fake GPS or GPS Spoofer, violates our Terms of Service and will result
                    in permanent blocking of your account and forfeiture of your balance.
                    Additionally, using our Services, creating an account, or playing
                    games from a Restricted Territory using IP or GPS masking software
                    constitutes a violation of applicable laws, subjecting you to
                    penalties and legal action.
                </p>

                <p>
                    All transactions on our Website/Mobile App are conducted in Indian
                    rupees, and upon registration, a user account is created to track your
                    transactions. Payments for cash game participation must be made
                    through your Vyka Rummy user account, with cash prizes credited to
                    your Vyka Rummy Withdrawal Balance.
                </p>

                <p>
                    It is essential to ensure that the payment instrument used is solely
                    yours and is used exclusively to add cash to your user account. Add
                    Cash limits, both Daily and Monthly, are subject to default settings,
                    which may be adjusted at our discretion based on conditions we deem
                    appropriate.
                </p>

                <p>
                    Payments processed via third-party payment gateways, including Credit
                    card, Debit card, Prepaid Cash card, UPI, and internet banking, are
                    subject to the terms and procedures of these intermediaries. We are
                    not responsible for delays or denials/rejections at the intermediary's
                    end.
                </p>

                <p>
                    We reserve the right to cancel transactions at our discretion, in
                    which case the payment will be reversed, and funds credited back to
                    your payment instrument. Players' funds are held in trust by us in
                    designated bank accounts, separate from corporate funds. In the event
                    of insolvency, your claim on deposits will be prioritized as
                    permissible by law.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Your Password Is Your Responsibility</h2>
                <p>
                    Upon registering on our Website/Mobile App, it is your individual
                    responsibility to safeguard your passwords and ensure they are not
                    disclosed to anyone, including friends, family members, or third
                    parties. It is crucial to create a strong password comprising a
                    minimum of 8 characters, incorporating capital letters, digits, and
                    special characters, to enhance security and prevent unauthorized
                    access. We cannot be held liable if your user account is accessed by
                    others using your password. The criteria for creating a strong
                    password apply exclusively to passwords generated on Vyka Rummy and do
                    not extend to passwords created on external social platforms (such as
                    Facebook and Google) and their subsequent use for logging into Vyka
                    Rummy.
                </p>

                <div>
                    <ul>
                        <li>Minimum 8 characters</li>
                        <li>At least one capital letter (i.e A-Z) </li>
                        <li>Don't use your email id/username</li>
                        <li>Atleast one number (i.e 0-10)</li>
                        <li>Atleast one @,#,$,%,^,& symbol</li>
                    </ul>
                </div>
            </div>

            <div className={styles.content}>
                <h2>Technical Failures</h2>
                <p>
                    At times, you may encounter disruptions, including but not limited to
                    errors, disconnections, or interruptions in communication, due to
                    issues with your internet services, software, or hardware while
                    accessing our Services.
                </p>

                <p>
                    Vyka Rummy cannot be held liable for any network disconnections on
                    your computer, Website/Mobile device, or other devices caused by
                    faults or errors on your end, nor will it compensate for any resulting
                    losses. In the event of a service disruption at Vyka Rummy's end
                    during a game, as determined solely by Vyka Rummy, the game will be
                    canceled, and your points/cash will be refunded regardless of the
                    game's status.
                </p>

                <p>
                    When participating in a cash game, you assume full responsibility for
                    the risk of internet disconnection, communication interruptions
                    between your device and Vyka Rummy's servers, game lag, freezing, or
                    other issues attributable to your device or network. Vyka Rummy is not
                    responsible for disconnections from its servers unless it is due to a
                    server crash.
                </p>

                <p>
                    If a short-term disconnection or disruption occurs during a game, you
                    are expected to remain at the table and can resume play once the
                    connection is restored, unless you have been eliminated.
                </p>
            </div>

            <div className={styles.content}>
                <h2>No Warranty</h2>
                <p>
                    Vyka Rummy is dedicated to delivering updated, accurate, and
                    comprehensive information through our Website/Mobile App. However, it
                    is important to understand that we do not guarantee the suitability of
                    the information presented in documents or any other content published
                    on our Website/Mobile App. Therefore, all information, graphics, and
                    images are provided "as is" without any express or implied warranties
                    from us.
                </p>

                <p>
                    We expressly disclaim all warranties regarding this information,
                    including any implied warranties and conditions of merchantability,
                    title, non-infringement, fitness for a particular purpose, and
                    accuracy of our site, software, and rummy games.
                </p>

                <p>
                    We do not warrant that our online rummy game servers will operate
                    error-free or uninterrupted, or that they will be free from bugs,
                    defects, or viruses of any kind. Therefore, we reserve the right to
                    determine, at our sole discretion, whether any issues on our end are
                    responsible for errors, disruptions, or malfunctions.
                </p>

                <p>
                    If it is determined that you intentionally caused any malfunction or
                    disruption, we reserve the right to limit your participation in our
                    rummy games or tournaments or terminate your account. Vyka Rummy is
                    not liable for any hypothetical or potential wins in unfinished rummy
                    games or tournaments on the Website/Mobile App.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Software Usage</h2>
                <p>
                    The use of our software for commercial purposes is strictly
                    prohibited. Our software is designed solely for personal entertainment
                    through playing rummy games. Your Vyka Rummy account should be
                    utilized exclusively for gameplay on the Website/Mobile App, and any
                    attempt to manipulate, decode, modify, disassemble, integrate,
                    dis-integrate, decompile, reverse-engineer, or re-engineer our
                    software is prohibited.
                </p>

                <p>
                    It is important to note that the use of bots or automated play is
                    prohibited, including the use of artificial intelligence such as
                    robots, in connection with our rummy games and software. All actions
                    performed on or related to our Website/Mobile App must be executed
                    solely through the user interface accessible on our Website/Mobile
                    App.
                </p>

                <p>
                    Violation of these terms may result in the suspension or termination
                    of your Vyka Rummy account or other actions deemed appropriate by Vyka
                    Rummy at its sole discretion.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Fair Play Policy</h2>
                <p>
                    Ensuring fair play on Vyka Rummy is of paramount importance to us, and
                    we diligently monitor all user actions, including deposits, identity
                    verification, and gameplay, to maintain a safe, legal, and equitable
                    environment for all users.
                </p>

                <p>
                    Each game on our platform must be played individually, without
                    collaboration or team efforts. To prevent malpractice and collusion,
                    we implement stringent measures, including continuous monitoring and
                    recording of user actions.
                </p>

                <p>
                    Any violation of our Fair Play Policy, such as unauthorized deposits
                    or collusion during gameplay, will result in swift and serious action,
                    including the permanent termination of the offending user's account.
                </p>


                <h3>Anti-Cheating and Anti-Collusion:</h3>
                <ul>
                    <li> Users are prohibited from using external assistance or
                        third-party software to modify or manipulate the Website/Mobile App.
                        Any attempt to do so is strictly forbidden.
                    </li>
                    <li>
                        Forming teams or engaging in collusion to cheat during games is
                        strictly prohibited. We maintain a zero-tolerance policy for such
                        behavior and take appropriate action against violators.
                    </li>
                    <li>Private tables created through the "Play with Friends" feature
                        lack monitoring capabilities, and we do not assume responsibility for
                        any collusion or cheating occurring at such tables.
                    </li>
                </ul>
                <h3>Money Laundering:</h3>
                <ul>
                    <li>
                        Engaging in activities that may be construed as money laundering,
                        including withdrawing funds without participating in cash games or
                        intentionally losing to specific players, is prohibited.
                    </li>
                </ul>
                <h3>Anti-Spamming:</h3>
                <ul>
                    <li>
                        Sending unsolicited communications or spam emails to solicit
                        registrations or promote Vyka Rummy is strictly prohibited.
                    </li>
                </ul>

                <h3> Multiple Accounts:</h3>
                <ul>
                    <li>
                        Users are limited to a single account and are prohibited from creating
                        or using multiple accounts.
                    </li>
                </ul>
                <h3>Non-Use:</h3>
                <ul>
                    <li>
                        Content uploaded or distributed through the Website/Mobile App must
                        comply with all applicable laws and must not contain objectionable or
                        illegal material.
                    </li>
                </ul>
                <h3>Inappropriate Usernames:</h3>
                <ul>
                    <li>
                        Users must promptly provide an alternate username if their chosen name
                        is deemed indecent or offensive.
                    </li>
                </ul>
                <h3>Additional Provisions:</h3>
                <ul>
                    <li>
                        Various additional provisions prohibit activities such as framing the
                        Website/Mobile App, engaging in commercial use, disseminating viruses,
                        or attempting to access Services through unauthorized means
                    </li>

                    <li>
                        Violation of any of these provisions may result in account termination
                        or further action at our discretion. We are committed to upholding
                        fair play and maintaining a secure and enjoyable gaming environment
                        for all users.
                    </li>
                </ul>
            </div>

            <div className={styles.content}>
                <h2>No Bribes, Gifts or Favors</h2>
                <p>
                    You shall not offer any Vyka Rummy employee any bribe, gifts,
                    services, compensation, or any other favor that might influence or
                    might appear to influence the employee in performing his/her duties at
                    Vyka Rummy. Vyka Rummy employees shall not solicit any bribe, gifts,
                    or services from you. Offering Vyka Rummy employees any cash, cash
                    equivalents, gifts, gift cards, services, or favors is strictly
                    prohibited.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Fair Play Deposit and Withdrawal Policy</h2>
                <p>
                    If you are a cash player on Vyka Rummy, your account balance is
                    divided into two accounts: Deposit Account Balance and Withdrawable
                    Account Balance. The Deposit Account Balance includes the amount you
                    have deposited, the amount you paid as the entry fee in games that you
                    won, and the amount you have earned as a bonus. The Withdrawable
                    Balance only includes the net amount that you have won. For clarity,
                    (net amount won = amount won – entry fee paid by the winner – platform
                    fee).
                </p>

                <p>
                    You can only withdraw a maximum amount equal to your Withdrawable
                    Balance. You cannot withdraw your deposit balance without using it for
                    playing cash games/tournaments on Vyka Rummy.
                </p>

                <p>
                    Subject to the Withdrawal Restrictions specified below, you may
                    withdraw your cash winnings to a bank account in India, using an
                    electronic bank-to-bank transfer (NEFT/IMPS) method.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Withdrawal Restrictions</h2>
                <p>
                    Sree Vyka Games reserves the right to implement personalized
                    withdrawal policies for individual players, encompassing various
                    limits and fees such as daily and monthly withdrawal limits, paid
                    withdrawal limits, and free withdrawal limits within specific time
                    frames. If a user surpasses the designated free withdrawal limits
                    within the specified time frame, withdrawal fees may be applicable for
                    subsequent transactions during that period. Please note that free
                    withdrawal limits may not be universally available to all users.
                </p>

                <p>
                    It is important to acknowledge that these withdrawal policies,
                    including limits and fees, are subject to modification at the
                    exclusive discretion of Sree Vyka Games, with adjustments tailored to
                    individual players.
                </p>

                <p>
                    By utilizing our withdrawal services, you agree to abide by the
                    following conditions:
                </p>

                <ul>
                    <li>
                        You may request withdrawal of funds from your user account at any
                        time, subject to bonus/prize money withdrawal restrictions.
                    </li>

                    <li>
                        Users cannot withdraw bonuses. Real money winnings from cash games
                        and tournaments are eligible for withdrawal only after fulfilling
                        specific conditions, including making at least one cash deposit on
                        Vyka Rummy. Bonuses and offers can be redeemed following the terms and
                        conditions specified, including playing the required number of cash
                        games.
                    </li>

                    <li>
                        Withdrawal requests will be processed following successful
                        completion of KYC and withdrawal verification, with funds transferred
                        via IMPS and NEFT electronic transfer. Sree Vyka Games reserves the
                        right to reverse funds to the payment/deposit instrument used for
                        adding cash to the user's Vyka Rummy account.
                    </li>

                    <li>
                        Presently, all withdrawals on Vyka Rummy are free of charges.
                        However, in the future, if withdrawal fees are implemented, users will
                        be notified in advance, and the applicable fee will be charged
                        accordingly.
                    </li>
                    <li>
                        Every effort will be made to process withdrawal requests within 24
                        hours of submission. Please allow an additional 48 hours for the bank
                        to deposit the funds into your account. Delays may occur due to
                        verification processes and transaction completion, including national
                        holidays and service disruptions. Sree Vyka Games disclaims any
                        liability for compensation resulting from delays in remitting payments
                        from your user account.
                    </li>
                </ul>
            </div>

            <div className={styles.content}>
                <h2>Bonuses</h2>
                <p>
                    Vyka Rummy provides eligible users with bonuses upon depositing funds
                    using designated bonus codes or participating in specific promotions,
                    subject to the terms and conditions outlined for each promotion. These
                    bonuses are non-cashable and cannot be withdrawn as cash. It is
                    essential to familiarize yourself with the specific terms and
                    conditions governing the utilization of bonuses and bonus offers. Any
                    bonuses that remain unused beyond the validity period specified in the
                    corresponding promotion's terms and conditions will expire.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Terms of Payment</h2>

                <div className={styles.inner_content}>
                    <ul>
                        <li>
                            Vyka Rummy imposes specific payment terms regarding transactions
                            conducted on its platform, including participation fees for skill
                            games. These terms are outlined as follows:
                        </li>
                        <li>
                            Users acknowledge that the predetermined participation fee for skill
                            games includes both Vyka Rummy's fixed platform fee and the user's
                            contribution to the prize pool. Vyka Rummy reserves the right to
                            adjust the platform fee at its discretion, with changes applicable
                            to all game formats and users.
                        </li>

                        <li>
                            All user payments are held in a separate Trust Account until
                            winners are determined and prizes distributed. Vyka Rummy only
                            receives its share of the platform fee from this account and has no
                            control over user funds.
                        </li>
                        <li>
                            Vyka Rummy may charge a platform fee, subject to periodic
                            variations. This fee, inclusive of applicable taxes, is debited from
                            the user's account balance, with transaction details recorded in the
                            user's transaction history.
                        </li>
                        <li>
                            Users contribute a predetermined amount to the prize pool of cash
                            skill games, with Vyka Rummy charging a nominal platform service fee
                            on the final prize pool. The user's contribution is debited from
                            their account balance within the Trust Bank account.
                        </li>
                        <li>
                            Vyka Rummy provides three types of accounts for payment
                            processing: Deposit Balance Account, Withdrawable Balance Account,
                            and Bonus Account. Amounts from the Deposit Balance Account cannot
                            be transferred to the Withdrawable Balance Account
                        </li>
                        <li>
                            Winnings, after deducting entry fees and platform fees, are
                            credited to the user's Withdrawable Balance Account.
                        </li>
                        <li>
                            Funds remitted via designated payment gateways are credited to the
                            user's Deposit Balance Account.
                        </li>
                        <li>
                            Withdrawals are permitted only from the Withdrawable Balance
                            Account, with all credited amounts required to be utilized within
                            365 days. Inactive accounts may be subject to Vyka Rummy's Inactive
                            Accounts and Unused Funds Policy.
                        </li>
                        <li>
                            Withdrawal requests are processed within a reasonable timeframe,
                            contingent upon KYC verification and other requirements. Users must
                            provide valid identification and banking details for withdrawal
                            processing.
                        </li>
                        <li>
                            Users are granted bonuses for promotional purposes, subject to
                            specific terms and conditions. Bonuses cannot be transferred and
                            must be used within specified timeframes.
                        </li>
                        <li>
                            Users confirm transactions upon initiating them and agree to be
                            bound by the transactional terms. Vyka Rummy may refund amounts in
                            exceptional circumstances, subject to applicable charges and terms.
                        </li>
                        <li>
                            Transaction processing times may vary, with funds taking up to 72
                            hours to reflect in user accounts. Users waive any claims against
                            Vyka Rummy for transaction delays.
                        </li>
                        <li>
                            Once confirmed, transactions are final, and cancellations are not
                            permitted. Additional terms and conditions may apply to
                            transactions, with conflicting clauses subject to the prevailing
                            terms.
                        </li>
                    </ul>
                </div>
            </div>

            <div className={styles.content}>
                <h2>Cancellation/Refund Policy</h2>

                <div className={styles.inner_content}>
                    <ul>
                        <li>
                            For any concerns or issues regarding your Deposits or Withdrawals, kindly reach out to us at grievance@vykagames.com.
                        </li>
                        <li>
                            If a deposit transaction fails to add the requested funds to your gaming account, please contact our support team within 4 hours.
                        </li>

                        <li>
                            Similarly, if a withdrawal transaction does not reflect the requested funds in your bank or UPI account, please notify our support team within 12 hours.
                        </li>
                        <li>
                            We undertake a thorough investigation from our end and promptly escalate your concern to our payment gateway team for resolution.
                        </li>
                        <li>
                            Our turnaround time for addressing deposit-related concerns is 72 hours. However, if the issue stems from the payment gateway, resolution time aligns with their guidelines.
                        </li>
                        <li>
                            Likewise, for withdrawal-related concerns, our turnaround time is 72 hours. Should the issue involve the payment gateway or your withdrawal bank, resolution time follows their respective guidelines.
                        </li>
                    </ul>
                    <p>
                        We are committed to ensuring a seamless experience for all our users and appreciate your cooperation in adhering to these guidelines.
                    </p>
                </div>
            </div>

            <div className={styles.content}>
                <h2>Breach and Consequences</h2>
                <p>
                    If you engage in any activities that violate our Terms of Service,
                    involve fraudulent or unlawful behavior, or pose a threat to the
                    interests of Vyka Rummy, our users, or the public, we reserve the
                    right to take appropriate action, including but not limited to the
                    following:
                </p>

                <div>
                    <ul>
                        <li>Temporary suspension of your account.</li>
                        <li>Permanent termination or blocking of your account.</li>
                        <li>Initiating legal proceedings as deemed necessary.</li>
                        <li>
                            Furthermore, if you are found to be involved in any disallowed
                            activities, Vyka Rummy may declare void any actions undertaken by
                            you on our platform and withhold any funds associated with such
                            activities. This includes retaining the entire amount or a portion
                            thereof attributable to the disallowed activity. In cases where
                            only a portion of the amount is withheld, Vyka Rummy will return
                            the remaining portion to its original source. It is understood
                            that you waive any rights to claim compensation or consideration
                            for such actions taken against your account due to engaging in
                            disallowed activities
                        </li>
                    </ul>
                </div>
                <p>
                    These actions are taken without prejudice to any other rights and
                    remedies available to us under applicable laws or equitable
                    principles.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Governing Law, Dispute Resolution, and Jurisdiction </h2>
                <p>
                    These Terms of Service are subject to the laws of India. The courts of
                    Hyderabad, India, shall have exclusive jurisdiction over any disputes
                    arising from or relating to the Services provided by Vyka Rummy, the
                    interpretation and enforceability of these Terms, or the rights and
                    obligations of the users and Vyka Rummy. This includes the authority
                    to grant interim relief in arbitration disputes. All such matters
                    shall be governed by Indian law.
                </p>

                <p>
                    In the event of a legal dispute, the party initiating the dispute must
                    provide written notification to the other party. Both parties will
                    attempt to resolve the dispute through discussions within fifteen (15)
                    days of receiving the notification. If resolution cannot be reached,
                    the dispute shall be settled through arbitration.
                </p>

                <p>
                    Arbitration proceedings will take place in Hyderabad, India, and will
                    be conducted in English in accordance with the Arbitration and
                    Conciliation Act, 1996. The arbitration award will be final and
                    binding, with each party responsible for its own arbitration costs
                    unless otherwise determined by the arbitrator. The arbitrator may
                    issue interim orders and enforceable awards, including specific
                    performance orders, with a reasoned decision.
                </p>

                <p>
                    These Terms do not prevent Vyka Rummy from seeking interim or
                    permanent equitable relief from any court with jurisdiction, either
                    before, during, or after arbitration proceedings, to protect its
                    interests. Pursuing equitable relief does not waive Vyka Rummy's right
                    to pursue monetary damages through arbitration.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Content Copyright</h2>
                <p>
                    All content, materials, and information provided on our Website/Mobile
                    App, collectively referred to as "Content," are protected by
                    intellectual property rights. This includes various online items such
                    as images, designs, multimedia clips, graphics, logos, information,
                    text content, games, software, animation, marks, hyperlinks, and
                    pictures. The Content, whether owned by Vyka Rummy or third parties,
                    is safeguarded by applicable intellectual property laws. Furthermore,
                    Vyka Rummy reserves the right to record and utilize all chat content,
                    images, emails, and recommendations sent by users, as they constitute
                    part of the Website/Mobile App's Content.
                </p>

                <p>
                    Vyka Rummy retains all rights, titles, and interests in the Services,
                    including all intellectual property rights associated with the Content
                    and other elements related to the Website/Mobile Application. Subject
                    to compliance with the Terms, users are granted a limited right to use
                    the Services for personal, non-commercial purposes. Users are
                    prohibited from selling or trading Content, and any rights not
                    expressly granted are reserved by Vyka Rummy.
                </p>

                <p>
                    Users agree to abide by all applicable laws, rules, regulations, and
                    guidelines when accessing and using the Services and Content.
                    Redistribution, transmission, assignment, sale, broadcast, rental,
                    sharing, lending, modification, adaptation, editing, sublicensing, or
                    other transfer or use of the Content is strictly prohibited unless
                    expressly permitted. No rights or licenses to the Content are granted
                    except as provided in the Terms.
                </p>

                <p>
                    Users must promptly notify Vyka Rummy in writing of any unauthorized
                    use or infringement of the Content or Vyka Rummy's intellectual
                    property rights. Additionally, the Website/Mobile App may contain
                    information and hyperlinks to third-party websites. Vyka Rummy
                    disclaims responsibility for the quality, integrity, or accuracy of
                    third-party content and users rely on such content at their own
                    discretion and risk. Visiting third-party content is subject to their
                    respective terms and conditions, and Vyka Rummy does not endorse or
                    control such websites.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Prohibited Content</h2>
                <p>
                    You acknowledge and agree that any modification to the Services is
                    strictly prohibited. Furthermore, you agree to utilize the Services
                    only for purposes explicitly permitted by these Terms. Specifically,
                    you agree not to:
                </p>

                <div className={styles.inner_content}>
                    <ul>
                        <li>Violate any laws or encourage others to do so.</li>
                        <li>Seek to profit in any manner.</li>
                        <li>
                            Share content containing falsehoods or misrepresentations.
                        </li>
                        <li>
                            Post content that may endanger life or result in physical harm.
                        </li>
                        <li>
                            Share content that is obscene, pornographic, invasive of
                            privacy, defamatory, harassing, hateful, racially or ethnically
                            offensive, or otherwise inappropriate.
                        </li>
                        <li>Post content harmful to children.</li>
                        <li>
                            Share content that threatens the unity, integrity, defense,
                            security, or sovereignty of India, or promotes unlawful activity
                            or offensive behavior.
                        </li>
                        <li>
                            Post unsolicited or unauthorized advertising, spam, or other
                            unauthorized solicitation.
                        </li>
                        <li>
                            Share materials protected by intellectual property rights
                            without proper permissions.
                        </li>
                        <li>
                            Deploy contaminants intended to harm or interfere with the
                            operation of the Services.
                        </li>
                        <li>
                            Attempt to bypass security measures or gain unauthorized access
                            to the Services.
                        </li>
                        <li>
                            Engage in automated use of the system or attempt to gather
                            personal data about other users.
                        </li>
                        <li>
                            Attempt to impersonate another user or engage in fraudulent
                            behavior.
                        </li>
                        <li>
                            Modify, reverse engineer, or attempt to derive the source code
                            of the Services.
                        </li>
                        <li>
                            Make unauthorized use of the Services or gather information
                            about other users' usage.
                        </li>
                        <li>
                            Violate the security of the Website/Mobile App or Services by
                            accessing unauthorized data, accounts, or networks.
                        </li>
                    </ul>
                </div>

                <p>
                    Failure to comply with these guidelines may result in civil or
                    criminal liability. Vyka Rummy reserves the right to investigate such
                    violations and may involve law enforcement authorities if necessary.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Promotions</h2>
                <p>
                    Upon registering with Vyka Rummy, you will receive notifications
                    regarding various offerings such as special promotions, updates,
                    contests, and account activities via SMS/RCS, emails, and direct mails.
                    Certain promotions by Vyka Rummy necessitate users to undergo KYC
                    verification to avail themselves of the benefits. Failure to have your
                    contact information or KYC details (ID, address, email, or
                    Website/Mobile number) approved or verified will result in the
                    forfeiture of benefits associated with such promotions, including
                    promotional amounts and prizes. Prior to participating in any
                    promotions or contests, please acquaint yourself with the specific
                    terms and conditions.
                </p>

                <p>
                    Comprehensive details of all our promotions are accessible in the
                    "Promotions" section on the Website/Mobile App. Each promotion is
                    accompanied by its distinct Terms and Conditions, elaborately outlined
                    on the respective promotion pages. Cash bonuses and deposit bonuses
                    are subject to additional terms and conditions, inclusive of specific
                    time constraints. These stipulations, alongside supplementary terms,
                    may vary across different offers as per the respective bonus schemes.
                    Note that any bonus offer cannot be concurrently claimed with other
                    bonus offers available from Vyka Rummy at the time. Upon activation of
                    a bonus code, any previously utilized bonus codes and offers will
                    automatically become null and void, and you will only receive benefits
                    from the active bonus code.
                </p>

                <p>
                    All games, contests, bonuses, and cashback offers featured in the
                    "Promotions" section are subject to potential discontinuation or
                    cancellation without prior notice. Vyka Rummy bears no responsibility
                    or liability for such cancellations and will not provide any
                    compensation to users except for refunding the applicable entry fee.
                    In the event of disputes, the decision of Vyka Rummy management shall
                    be deemed final and binding.
                </p>

                <div className={styles.content}>
                    <h3>Tournament Tickets</h3>
                    <ul>
                        <li>
                            Tickets are strictly non-transferable and can only be utilized
                            by the user who purchases or earns them.
                        </li>
                        <li>
                            They hold no cash value and cannot be exchanged for monetary
                            compensation under any circumstances.
                        </li>
                        <li>
                            Tickets do not possess any inherent monetary worth and cannot be
                            redeemed for cash or any other form of consideration. Their sole
                            purpose is for participation in designated tournaments or games
                            for which they are issued.
                        </li>
                        <li>Unused tickets will expire after a certain period.</li>
                        <li>
                            Holders of tickets are not considered unsecured creditors of the
                            company; rather, tickets serve as components of sales promotions
                            and marketing initiatives.
                        </li>
                    </ul>
                </div>
            </div>

            <div className={styles.content}>
                <h2>Refund Policy</h2>
                <p>
                    In instances where there is an accidental or erroneous cash credit to
                    any party, user, or company, we reserve the right to independently
                    initiate or request a refund within a two-week period from the
                    initiation of such transaction. Vyka Rummy will facilitate the refund
                    process if it is determined to be a genuine case of accidental or
                    erroneous cash credit, either upon your request or following our own
                    internal investigation. The refunded amount will be credited within
                    two weeks to the financial instrument, platform, or source account
                    used for the corresponding transaction. It is important to note that
                    any refund issued under this provision is subject to the sole
                    discretion of Vyka Rummy.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Cancellation of Games</h2>
                <p>
                    Vyka Rummy shall not be held liable for any losses arising from the
                    cancellation of games due to service outages resulting from failures
                    of our service providers, computer viruses, natural disasters, or any
                    other reasons beyond our control. You acknowledge that no refunds
                    shall be provided for game cancellations resulting from factors beyond
                    the reasonable control of Vyka Rummy.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Online Transactions</h2>
                <p>
                    To transfer funds to your Vyka Rummy cash account, transactions must
                    be conducted via third-party payment gateways. Vyka Rummy bears no
                    responsibility for the denial or rejection of any transactions, as
                    they are governed by the terms and conditions of the third-party
                    payment gateways. Vyka Rummy reserves the right to reverse
                    transactions, with the refunded amount credited to the financial
                    instrument/platform used by you for the transaction.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Account Validation</h2>
                <p>
                    Vyka Rummy retains the authority to periodically verify or
                    authenticate the personal details of players/users. This validation
                    process may involve communication via phone call or email. Should
                    initial attempts to reach you prove unsuccessful, multiple endeavors
                    will be made to establish contact. In the event of unsuccessful
                    validation or inability to reach you after exhausting all efforts, we
                    reserve the right, at our sole discretion, to either suspend your
                    account and refund your Vyka Rummy account balance to the financial
                    instrument/platform used for online payment, or confiscate your Vyka
                    Rummy account balance.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Refer and Win Cash</h2>
                <p>
                    Vyka Rummy has a “Refer & Earn” program in which users can refer their
                    friends to Vyka Rummy and get referral bonuses for successful
                    referrals.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Responsible Gaming</h2>
                <p>
                    As a responsible gaming company, we prioritize the well-being of our
                    players and advocate for responsible play. We maintain a vigilant
                    watch over player activities and promptly intervene if any signs of
                    compulsive behavior emerge. To ensure a positive gaming experience, we
                    implement various precautionary measures:
                </p>

                <div className={styles.inner_content}>
                    <ul>
                        <li>
                            Individuals under the age of 18 are strictly prohibited from
                            creating Vyka Rummy accounts or participating in games on our
                            Website/Mobile App.
                        </li>
                        <li>
                            Continuous monitoring of player activities occurs around the
                            clock, aided by an anti-fraud algorithm designed to identify
                            unfair practices, including collusion.
                        </li>
                        <li>
                            Robust anti-collusion measures are integrated into every rummy
                            game to uphold fair play standards.
                        </li>
                        <li>
                            Player data is securely stored using advanced encryption methods
                            to safeguard confidentiality.
                        </li>
                        <li>
                            Our Website/Mobile App incorporates features that restrict cash
                            deposit amounts.
                        </li>
                        <li>
                            To discourage irresponsible gaming habits, we enforce cash
                            deposit limits on the platform, preventing players from exceeding
                            reasonable spending thresholds.
                        </li>
                        <li>
                            KYC verification is conducted to ensure player protection and
                            enhance game security.
                        </li>
                        <li>
                            We actively promote responsible gaming practices and may issue
                            periodic notifications or alerts to players spending excessive
                            amounts of time on Vyka Rummy, advising them to take breaks as
                            necessary.
                        </li>
                        <li>
                            Players have the option to utilize our self-exclusion feature,
                            enabling them to voluntarily abstain from Vyka Rummy games for
                            specified durations ranging from 48 hours to 6 months. Once a
                            self-exclusion request is submitted and processed, players are
                            restricted from accessing our games for the designated period,
                            with no option for reversal or modification. Additionally,
                            creation of another Vyka Rummy account is prohibited during this
                            time. Any remaining account balance will be retained until the
                            player's return, unless a withdrawal request is initiated.
                        </li>
                    </ul>
                </div>
            </div>

            <div className={styles.content}>
                <h2>Voluntary Termination of Account</h2>
                <p>
                    You have the option to discontinue using our gaming services and close
                    your account by submitting a written request to us, clearly outlining
                    your intention. Upon receipt of your request, Vyka Rummy will assess
                    your case and, at its discretion, proceed to terminate your account.
                    Any cash balance available in your account will be refunded to you
                    within 21 working days.
                </p>
                <p>
                    Please be aware that any activities conducted in your account
                    subsequent to your email request for termination, including
                    participation in cash games, tournaments, depositing, and withdrawing
                    funds, will be considered as actions undertaken solely by you. Vyka
                    Rummy bears no responsibility for such actions.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Inactive Accounts and Unused Funds</h2>
                <p>
                    If your Vyka Rummy account remains inactive for a period exceeding 365
                    days and contains an unused cash balance, whether categorized as
                    Deposit Balance or Withdrawable Balance, you will receive reminders
                    prompting you to reactivate your account. To reactivate your account,
                    you must log in and engage in a free game, cash game, or tournament,
                    or conduct a cash deposit or withdrawal.
                </p>

                <p>
                    Following the reminders sent to your registered email address and
                    phone number via SMS/RCS, failure to activate your account will result in
                    the forfeiture of your account balance to Vyka Rummy, at Vyka Rummy's
                    sole discretion. All reminders will be directed to the email address
                    or phone number listed in your profile as part of the account details
                    provided to Vyka Rummy.
                </p>

                <p>
                    Accounts found to be in violation of our Terms of Service will not
                    receive reminders, and any unused funds in such accounts will be
                    subject to the terms outlined in our Breach and Consequences policy.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Indemnity</h2>
                <p>
                    To the fullest extent permitted by law, and as a condition of using
                    our Services, you hereby agree to indemnify, defend, and hold us
                    harmless from and against all liabilities, awards, expenses,
                    litigation costs, and reasonable attorney fees that we may incur or
                    suffer in connection therewith. This includes any direct, indirect, or
                    consequential losses, such as loss of profit and reputation, arising
                    from claims, suits, actions, damages, penalties, injuries, demands,
                    interest, and/or awards imposed by any person with a connection to:
                </p>

                <div className={styles.inner_content}>
                    <ul>
                        <li>
                            Unauthorized access to your user account using your username or
                            password to access our rummy game services, whether with or
                            without your authorization.
                        </li>
                        <li>
                            Use, misuse, or abuse of your user account on the Vyka Rummy
                            Website/Mobile App in any manner.
                        </li>
                        <li>
                            Offensive, illegal, or defamatory conduct carried out by any
                            players, intended to mislead, defame, threaten, or cause harm,
                            even if originating from another player using your account.
                        </li>
                        <li>
                            Infringement of other parties' intellectual property rights by
                            publishing any content on the Vyka Rummy Website/Mobile App.
                        </li>
                        <li>
                            Disruptions such as technical failures, disconnections,
                            interruptions, system breakdowns, data transmission manipulations,
                            data corruption or loss, communication line failures, distributed
                            denial of service attacks, viruses, or other adverse technological
                            incidents related to your access to or use of our services for
                            playing rummy on the Website/Mobile App.
                        </li>
                    </ul>
                </div>

                <p>
                    You further agree to indemnify and hold harmless Vyka Rummy and its
                    affiliates ("Indemnified Parties") against any losses, expenses,
                    costs, or damages, including reasonable attorneys' fees, arising from:
                </p>
                <div className={styles.inner_content}>
                    <ul>
                        <li>
                            Your breach or failure to comply with the terms and conditions
                            of these Terms.
                        </li>
                        <li>Your access and use of the Content.</li>
                        <li>Your breach of applicable laws.</li>
                        <li>Your negligence or misconduct.</li>
                        <li>
                            Your infringement or misappropriation of intellectual property
                            rights of any third party.
                        </li>
                        <li>Vyka Rummy's execution of any of your instructions.</li>
                        <li>
                            The use of the Service by any other person using your device.
                        </li>
                    </ul>
                </div>

                <p>
                    Notwithstanding anything to the contrary contained in these Terms of
                    Service or elsewhere, you agree that Vyka Rummy's maximum aggregate
                    liability for all your claims in all circumstances is limited to
                    refunding only the Withdrawable Balance, if any, in your Vyka Rummy
                    account back to you.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Release and Limitations of Liability</h2>
                <p>
                    Users access the Services provided by Vyka Rummy voluntarily and at
                    their own risk. Vyka Rummy shall not, under any circumstances, be held
                    responsible or liable for any loss or damage sustained, including but
                    not limited to accidents, injuries, death, or loss of property, by
                    users or any other person or entity during the course of accessing
                    Vyka Rummy Services, including participation in the games or as a
                    result of accepting any prize.
                </p>

                <p>
                    By entering the games and accessing the Services provided therein,
                    users hereby release and agree to indemnify Vyka Rummy and/or any and
                    all of its directors, employees, partners, associates, and licensors
                    from and against all liability, cost, loss, or expenses arising out of
                    their access to the Services, including personal injury and damage to
                    property, whether direct, indirect, consequential, foreseeable, due to
                    some negligent act or omission on their part or otherwise.
                </p>

                <p>
                    Users shall be solely responsible for any consequences that may arise
                    due to their access to the Services, by conducting an illegal act or
                    due to non-conformity with these Terms of Service and other rules and
                    regulations in relation to the Services, including provision of
                    incorrect address or other personal details. Users also undertake to
                    indemnify Vyka Rummy and their respective officers, directors,
                    employees, and agents on the happening of such an event, including,
                    without limitation, the cost of attorney, legal charges, etc., on a
                    full indemnity basis for any loss/damage suffered by Vyka Rummy on
                    account of such act on the part of the users.
                </p>

                <p>
                    Users shall indemnify, defend, and hold Vyka Rummy harmless from any
                    third-party/entity/organization claims arising from or related to such
                    users’ engagement with Vyka Rummy or participation in any game. In no
                    event shall Vyka Rummy be liable to any user for acts or omissions
                    arising out of or related to the user's engagement with the
                    Website/Mobile App or his/her participation in any game(s).
                </p>

                <p>
                    In consideration of Vyka Rummy allowing users to access the Services,
                    to the maximum extent permitted by law, users waive and release each
                    and every right or claim, all actions, causes of actions (present or
                    future) each of them has or may have against Vyka Rummy, its
                    respective agents, directors, officers, business associates, group
                    companies, sponsors, employees, or representatives for all and any
                    injuries, accidents, or mishaps arising out of the provision of the
                    Services or related to the games or the prizes of the games.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Disclaimers </h2>
                <p>
                    To the extent permitted by law, neither Vyka Rummy nor its
                    parent/holding company, subsidiaries, affiliates, directors, officers,
                    professional advisors, or employees shall be held liable for the
                    deletion, failure to store, mis-delivery, or untimely delivery of any
                    information or material.
                </p>

                <p>
                    Vyka Rummy shall not be held responsible for any harm resulting from
                    downloading or accessing any information or material, the quality of
                    servers, games, products, services, or sites, or cancellation of
                    competitions and prizes. Vyka Rummy disclaims any responsibility for
                    accessibility issues caused by maintenance on servers or underlying
                    technology, failures of service providers, computer viruses, natural
                    disasters, or other causes beyond its reasonable control.
                </p>

                <p>
                    Any material accessed, downloaded, or obtained through Vyka Rummy is
                    at the user's discretion, competence, acceptance, and risk, and the
                    user will be solely responsible for any potential damage to their
                    computer system or loss of data resulting from such actions.
                </p>

                <p>
                    To the extent permitted by law, neither Vyka Rummy nor its partners,
                    licensors, or associates shall be liable for any direct, indirect,
                    incidental, special, or consequential damages arising out of the use
                    of or inability to use their sites, even if advised of the possibility
                    of such damages.
                </p>

                <p>
                    Vyka Rummy Services, events, or games requiring specific permission or
                    authority from statutory or government bodies shall be deemed canceled
                    or terminated if such permission is not obtained or denied, whether
                    before or after their availability.
                </p>

                <p>
                    To the extent permitted by law, in the event of suspension or closure
                    of any Services, events, or games, users (including participants)
                    shall not be entitled to make any demands or claims of any nature
                    whatsoever.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Miscellaneous</h2>
                <p>
                    Vyka Rummy may be obligated under certain legislations to notify users
                    of specific events. Users acknowledge and consent that such notices
                    will be effective upon Vyka Rummy posting them on the Website/Mobile
                    App or delivering them to the user through the email address provided
                    during registration. Users can update their email address by logging
                    into their account on the Website/Mobile App. Failure to provide
                    accurate information relieves Vyka Rummy of liability for failure to
                    notify the user.
                </p>

                <p>
                    Vyka Rummy shall not be held liable for any delay or failure to
                    perform resulting from causes beyond its reasonable control, including
                    but not limited to acts of God, war, terrorism, riots, embargoes,
                    civil or military actions, natural disasters, accidents,
                    infrastructure failures, strikes, or shortages. In such cases, Vyka
                    Rummy reserves the right to cancel any related games and process
                    appropriate refunds for all users.
                </p>

                <p>
                    The failure of Vyka Rummy to exercise or enforce any right or
                    provision of these Terms of Service shall not constitute a waiver of
                    such right or provision.
                </p>

                <p>
                    Users agree that any claim or cause of action arising from the use of
                    the Website/Mobile App or these Terms must be filed within thirty (30)
                    days of the claim or cause of action arising, or be forever barred.
                </p>

                <p>
                    These Terms of Service, including all incorporated terms, conditions,
                    and policies, constitute the entire agreement between users and Vyka
                    Rummy, superseding any prior agreements.
                </p>

                <p>
                    If any part of these Terms of Service is deemed indefinite, invalid,
                    or unenforceable, the remaining provisions shall remain in full force.
                </p>

                <p>
                    Vyka Rummy reserves the right to moderate, restrict, or ban the use of
                    the Website/Mobile App, either to specific users or in general, in
                    accordance with its policies, without notice.
                </p>

                <p>
                    Vyka Rummy may, at its sole discretion, permanently close or
                    temporarily suspend any Services.
                </p>
            </div>

            <div className={styles.content}>
                <h2>Grievance Redressal Mechanism</h2>
                <p>
                    If you have any complaints or grievances regarding (i) User Content
                    that you believe violates these Terms, (ii) your access to Vyka Rummy,
                    or (iii) User Content that you believe is prima facie obscene,
                    defamatory, or involves impersonation in an electronic form, please
                    address them to us by writing to:
                </p>
                <p>
                    In your complaint or grievance, please provide the following details:
                </p>

                <div className={styles.inner_content}>
                    <ul>
                        <li>
                            Your name and contact details: including name, address,
                            Website/Mobile number, and email address.
                        </li>
                        <li>
                            Your relationship to the subject matter of the complaint,
                            whether as the complainant or as a representative acting on behalf
                            of an affected person.
                        </li>
                        <li>
                            The name and age of the aggrieved or affected person, if
                            applicable, along with a statement, under penalty of perjury,
                            affirming your authorization to act on their behalf and to provide
                            their personal information to us regarding the complaint or
                            grievance.
                        </li>
                        <li>
                            A clear description of the complaint or grievance, including
                            specific identification of the User Content in question.
                        </li>
                        <li>
                            A statement indicating your belief, in good faith, that the User
                            Content violates these Terms.
                        </li>
                        <li>
                            A confirmation that the information provided in the complaint or
                            grievance is accurate.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </>
      
    );
}

export default TermsAndConditions;
