import React, { useEffect } from 'react';
import './App.css';
import Home from './components/home/Home';
import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";
import TermsAndConditions from './components/terms&Conditions/TermsAndConditions';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPloicy';
import Footer from './components/footer/Footer';
import LegalityLayout from './components/footerLayout/Legality';
import ThirteenCardsRummy from './components/footerContents/thirteencardsrummy/ThirteenCardsRummy';
import WinMoneyGameApp from './components/footerContents/winmoneygameapp/WinMoneyGameApp';
import OnlineRummyCashGames from './components/footerContents/onlinerummycashgames/OnlineRummyCashGames';
import PractiseGames from './components/footerContents/practisegames/PractiseGames';
import RummyRulesguide from './components/footerContents/rummyrulesguide/RummyRulesguide';
import HowToPlayIndianRummyOnlineWithVykaRummy from './components/footerContents/htpindiarummywithvykarummy/HowToPlayIndianRummyOnlineWithVykaRummy';
import HowToplayDealsRummyOnVyka from './components/footerContents/htpdealsrumm,yonvyka/HowToplayDealsRummyOnVyka';
import HowToPlayPointsRummyOnVykaRummy from './components/footerContents/htppointsrummyonvykarummy/HowToPlayPointsRummyOnVykaRummy';
import HoWToPlayPoolRummyOnVykaRummy from './components/footerContents/htppoolrummyonvykarummy/HoWToPlayPoolRummyOnVykaRummy';
import GameOfSkills from './components/footerContents/gameofskills/GameOfSkills';
import GSTDeductions from './components/footerContents/gstdeductions/GSTDeductions';
import TDSOnWinning from './components/footerContents/tdsonwinning/TDSOnWinning';
import FAQ from './components/footerContents/faq/FAQ';
import AboutLudo from './components/footerContents/aboutludo/AboutLudo';
import LudoLegalityLayout from './components/ludoLayout/LudoLegalityLayout';
import LudoRules from './components/footerContents/ludorules/LudoRules';
import GSTPolicy from './components/gstPolicy/GSTPolicy';
import GSTFaq from './components/gstFaq/GSTFaq';
import Download from './components/download/Download';
import DeactivationPolicy from './components/deactPolicy/DeactivationPolicy';
import ResponsibleGaming from './components/responsiblegaming/ResponsibleGaming';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/terms&conditions' element={<TermsAndConditions />} />
          <Route path='/deactivationpolicy' element={<DeactivationPolicy/>}/>
          <Route path='/responsiblegaming' element={<ResponsibleGaming/>}/>
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/gstpolicy' element={<GSTPolicy />} />
          <Route path='/gstfaq' element={<GSTFaq />} />
          <Route path='/download' element={<Download />} />
          <Route path='/rummy' element={<LegalityLayout />}>
            <Route path='/rummy/gameofskills' element={<GameOfSkills />} />
            <Route path='/rummy/13cardrummy' element={<ThirteenCardsRummy />} />
            <Route path='/rummy/winmoneygameapp' element={<WinMoneyGameApp />} />
            <Route path='/rummy/onlinerummycashgames' element={<OnlineRummyCashGames />} />
            <Route path='/rummy/practicegamesonvykarummy' element={<PractiseGames />} />
            <Route path='/rummy/rummyrulesguide' element={<RummyRulesguide />} />
            <Route path='/rummy/howtoplayindianrummyonlinewithvykarummy' element={<HowToPlayIndianRummyOnlineWithVykaRummy />} />
            <Route path='/rummy/howtoplaydealsrummyonvyka' element={<HowToplayDealsRummyOnVyka />} />
            <Route path='/rummy/howtoplaypointsrummyonvykarummy' element={<HowToPlayPointsRummyOnVykaRummy />} />
            <Route path='/rummy/howtoplaypollrummyonvyksrummy' element={<HoWToPlayPoolRummyOnVykaRummy />} />
            <Route path='/rummy/gstdeductions' element={<GSTDeductions />} />
            <Route path='/rummy/tdsonwinnings' element={<TDSOnWinning />} />
            <Route path='/rummy/faq' element={<FAQ />} />
          </Route>
          <Route path='/ludo' element={<LudoLegalityLayout />}>
            <Route path='/ludo/aboutludoonline' element={<AboutLudo />} />
            <Route path='/ludo/ludorules' element={<LudoRules />} />
            <Route />
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
