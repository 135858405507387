import React, { useEffect, useState } from 'react';
import styles from "./deactivationPolicy.module.scss"
import logo from "../../assets/images/logo.png"
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
const DeactivationPolicy = () => {
    const [seoData, setSeoData] = useState<any>({})
    const navigate = useNavigate()
    const [queries] = useSearchParams();
    const [isEmbed, setEmbed] = useState(queries.get('embed'))
    const handleRouteClick = () => {
        if (isEmbed === null) {
            navigate("/")
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const seodatalocal = JSON.parse(localStorage.getItem('seoData') || '{}');
        setSeoData(seodatalocal);
    }, []);

    return (
        
        <>
         <Helmet>
                <title>{"Account Deactivation Policy"}</title>
                <meta name="description" content={seoData?.data?.[9]?.description || "Default Description"} />
                <meta name="keywords" content={seoData?.data?.[9]?.keyword?.[9] || "Default Keywords"} />
            </Helmet>
        <div className={`${styles.termsandconditions} ${isEmbed && styles.termsandconditions1}`}>
            {
                isEmbed ? "" : <div style={{ cursor: "pointer" }} onClick={handleRouteClick} ><img style={{ width: "80px" }} src={logo} alt="logo" /></div>
            }
           
            <header className={`${styles.termsConditions} ${isEmbed && styles.termsConditions1}`}>Account Deactivation Policy</header>
            <div className={styles.content}>
                <h2>Voluntary Termination of Account </h2>
                <p>
                You have the option to discontinue using our gaming services and close your account by submitting a written request to us, clearly outlining your intention. Upon receipt of your request, Vyka Rummy will assess your case and, at its discretion, proceed to terminate your account. Any cash balance available in your account will be refunded to you within 21 working days.
                </p>

                <p>
                Please be aware that any activities conducted in your account subsequent to your email request for termination, including participation in cash games, tournaments, depositing, and withdrawing funds, will be considered as actions undertaken solely by you. Vyka Rummy bears no responsibility for such actions.
                </p>

                
            </div>
        </div>
        </>
      
    );
}

export default DeactivationPolicy;
