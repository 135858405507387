import React, { useEffect, useState } from "react";
import styles from "./gstfaq.module.scss"
import logo from "../../assets/images/logo.png"
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
export default function GSTFaq() {
    const [seoData, setSeoData] = useState<any>({})
    const navigate = useNavigate()
    const [queries] = useSearchParams();
    const [isEmbed, setEmbed] = useState(queries.get('embed'))
    const handleRouteClick = () => {
        if (isEmbed === null) {
            navigate("/")
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const seodatalocal = JSON.parse(localStorage.getItem('seoData') || '{}');
        setSeoData(seodatalocal);
    }, []);
    return (
        <>
         <Helmet>
                <title>{seoData?.data?.[7].title || "Privacy Policy"}</title>
                <meta name="description" content={seoData?.data?.[7]?.description || "Default Description"} />
                <meta name="keywords" content={seoData?.data?.[7]?.keyword?.[0] || "Default Content"} />
            </Helmet>
           <section className={`${styles.gst_cont} ${isEmbed && styles.gst_cont1}`}>
           {
                    isEmbed ? "" : <div style={{ cursor: "pointer" }}
                        onClick={handleRouteClick}
                    ><img style={{ width: "80px" }} src={logo} alt="logo" /></div>
                }
                    <div className={styles.header}>GST FAQ's</div>
                   <div className={styles.cont2}>
                   <p className={styles.heading}>What is the Current GST in Online Gaming?</p>
                    <p className={styles.content_cont}>Effective from 1st October 2023, as per the latest GST policy by the Indian Government, 28% GST is applicable on online gaming.</p>
                   </div>
                   <div className={styles.cont2}>
                    <p className={styles.heading}>Do I Need to Pay GST on Every Deposit?</p>
                    <p className={styles.content_cont}>Effective from 1st October 2023, as per the latest GST law, a GST rate of 28% will be charged on every deposit made by the players.</p>
                   </div>
                   <div className={styles.cont2}>
                    <p className={styles.heading}>What is the Current GST on Online Rummy?</p>
                    <p className={styles.content_cont}>The current GST on all deposits is 28% on online rummy card games.</p>
                   </div>
                   <div className={styles.cont2}>
                    <p className={styles.heading}>Is ₹28 GST Deducted on Deposit of ₹100?</p>
                    <p className={styles.content_cont}>The cash amount you add to your account is inclusive of 28% GST. Hence, ₹21.88 is the GST amount deducted as 28% of ₹78.12 when you deposit ₹100.</p>
                   </div>
                   <div className={styles.cont2}>
                    <p className={styles.heading}>What is the GST Cash Bonus?</p>
                    <p className={styles.content_cont}>When you make a deposit using the promo code NOGST, 100% of the GST amount is added as a cash bonus to your VYKA Rummy wallet instantly. For example, if you deposit ₹100 into your VYKA Rummy account, then 28% GST will be deducted. To cover the GST cost, VYKA Rummy will provide the GST amount as cashback into your VYKA Rummy wallet for your gameplay.</p>
                   </div>
                   <div className={styles.cont2}>
                    <p className={styles.heading}>How to Apply for a GST Cash Bonus?</p>
                    <p className={styles.content_cont}>The cash bonus is added to your wallet instantly when you make a deposit using the promo code NOGST. The code is automatically applied when you make an Add Cash. However, please check if the code is not applied and add it manually to make a deposit and get the 100% GST Cashback.</p>
                   </div>
                   <div className={styles.cont2}>
                    <p className={styles.heading}>Is there any GST on Deposits in VYKA Rummy?</p>
                    <p className={styles.content_cont}>Yes, in compliance with the government's new GST policy, there is a 28% GST applicable on deposits in VYKA Rummy.</p>
                   </div>
                   <div className={styles.cont2}>
                    <p className={styles.heading}>Is there any GST on Entry Fee or Winning Amount?</p>
                    <p className={styles.content_cont}>No, GST is only deducted at the time of making a cash deposit.</p>
                   </div>
                   <div className={styles.cont2}>
                    <p className={styles.heading}>Do I Need to Pay GST on Every Deposit?</p>
                    <p className={styles.content_cont}>Yes, whenever applicable, you need to pay both GST and TDS. GST is applicable when you Add Cash on VYKA Rummy, and TDS is applicable on net winnings during withdrawals.</p>
                   </div>
                   <div className={styles.cont2}>
                    <p className={styles.heading}>Can I Withdraw the GST Cash Bonus?</p>
                    <p className={styles.content_cont}>The GST Cash Bonus cannot be withdrawn. However, you can use the GST cashback to play Cash Games or Cash Tournaments and convert it into winnings, which can then be withdrawn.</p>
                   </div>
                   <div className={styles.cont2}>
                    <p className={styles.heading}>Do I Need to Pay GST on Every Deposit?</p>
                    <p className={styles.content_cont}>Effective from 1st October 2023, as per the latest GST law, a GST rate of 28% will be charged on every deposit made by the players.</p>
                   </div>

           </section>
        </>
    );
}