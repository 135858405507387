import React, { useEffect, useState } from 'react';
import styles from "../thirteencardsrummy/thirteenCardsRummy.module.scss"
import { Helmet } from 'react-helmet';
const TDSOnWinning = () => {
    const [seoData, setSeoData] = useState<any>({})
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const seodatalocal = JSON.parse(localStorage.getItem('seoData') || '{}');
        setSeoData(seodatalocal);
    }, []);
    return (
        <>
          <Helmet>
                <title>{seoData?.data?.[4].title || "Privacy Policy"}</title>
                <meta name="description" content={seoData?.data?.[4]?.description || "Default Description"} />
                <meta name="keywords" content={seoData?.data?.[4]?.keyword?.[0] || "Default Content"} />
            </Helmet>
        <div className={styles.thirRummy_cont}>
            <h3>TDS On Winnings</h3>

            <div className={styles.content}>
                <h3>What exactly are the New Tax Rules about?</h3>
                <ul>
                    <li>As per the Finance Act 2023, all Real Money Gaming Apps are mandated to deduct tax at the rate of 30% on users' Net Winnings at the time of withdrawal or on 31st March (i.e. end of Financial Year).</li>
                </ul>
            </div>
            <div className={styles.content}>
                <h3>What is 'Net Winnings'?</h3>
                <ul>
                    <li>Net Winnings is the difference between the total amount withdrawn and the total amount deposited in a financial year. Example -</li>
                    <li>
                        <table border={1}>
                            <thead className={styles.thead}>
                                <th>Total Deposit</th>
                                <th>Total Withdrawal</th>
                            </thead>
                            <tbody className={styles.tbody}>
                                <tr>
                                    <td>₹ 2000</td>
                                    <td>₹ 3000</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                </ul>
                <p>Here, Net Winnings= Total Withdrawal - Total Deposit
                    = ₹ 3000 - ₹ 2000 = ₹ 1000</p>
            </div>

            <div className={styles.content}>
                <h3>How is tax calculated on 'Net Winnings'?</h3>
                <ul>
                    <li>Tax is calculated at the rate of 30% of cumulative Net Winnings at the time of Withdrawal. Let's take the above example for reference. Here, the Net Winnings is ₹ 1000. Tax = 30% of ₹ 1000 = ₹ 300</li>
                </ul>
            </div>

            <div className={styles.content}>
                <h3>When will the tax be deducted?</h3>
                <ul>
                    <li>The tax will be calculated on the Net Winnings and deducted when a player makes a withdrawal or on 31st March (i.e. at the end of the Financial Year). The total tax liability on the net winnings during the year will be calculated and deducted from the player's wallet.</li>
                </ul>
            </div>

            <div className={styles.content}>
                <h3>Will tax be deducted if I withdraw an amount lesser than my total deposits?</h3>
                <ul>
                    <li>If your total withdrawal is less than your deposit, no tax will be deducted. Example -</li>
                    <li>
                        <table border={1}>
                            <thead className={styles.thead}>
                                <th>Total Deposit</th>
                                <th>Total Withdrawal</th>
                            </thead>
                            <tbody className={styles.tbody}>
                                <tr>
                                    <td>₹ 4000</td>
                                    <td>₹ 2000</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <li>Here, Net Winnings= Total Withdrawal - Total Deposit
                        = ₹ 2000 - ₹ 4000 = - ₹ 2000</li>
                    <li>As Net Winning is negative, there will be no tax deduction.</li>
                </ul>
            </div>

            <div className={styles.content}>
                <h3>How is tax calculated on 'Net Winnings'?</h3>
                <ul>
                    <li>Tax is calculated at the rate of 30% of cumulative Net Winnings at the time of Withdrawal. Let's take the above example for reference. Here, the Net Winnings is ₹ 1000. Tax = 30% of ₹ 1000 = ₹ 300</li>
                </ul>
            </div>

            <div className={styles.content}>
                <h3>Will my Opening A/c Balance be considered if I want to make a withdrawal at the start of the new Financial Year?</h3>
                <ul>
                    <li>Let's consider the following table as an example -</li>
                    <li>
                        <table border={1}>
                            <thead className={styles.thead}> 
                                <th>Opening A/c Balance as on 1st April 2023</th>
                                <th>Total Deposit</th>
                                <th>Total Withdrawal</th>
                            </thead>
                            <tbody className={styles.tbody}>
                                <tr>
                                    <td>₹ 1500</td>
                                    <td>₹ 2000</td>
                                    <td>₹ 3000</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <li>Tax is deducted on Net Winnings.</li>
                    <li>If at the start of the Financial Year, there's an Opening Balance, Net Winnings is calculated as
                        = (Total Withdrawal) - (Total Deposit) - (Opening Balance)
                        = ₹ 3000 - ₹ 2000 - ₹ 1500 = - ₹ 500</li>
                    <li>As the Net Winnings is negative, there will be no tax deduction. And so, your withdrawal amount post TDS deduction would be ₹ 3000 - ₹ 0 = ₹ 3000</li>
                </ul>
            </div>

            <div className={styles.content}>
                <h3>What will happen if there is some amount remaining in my VyKa Games Account at the end of the Financial Year?</h3>
                <ul>
                    <li>At the end of the Financial Year (i.e. 31st March 2024), if you have any amount remaining in your VyKa Games Account, tax will be deducted on that amount considering it as “withdrawal”. The remaining amount will be carried forward to the new Financial Year (i.e. 1st April 2025). Let's consider the following table as an example -</li>
                    <li>
                        <table border={1}>
                            <thead className={styles.thead}>
                                <th>Opening A/c Balance as on 1st April 2023</th>
                                <th>Total Deposit</th>
                                <th>Total Withdrawal</th>
                                <th>Closing A/c Balance as on 31st March 2024</th>
                                <th>Opening A/c Balance as on 1st April 2025</th>
                            </thead>
                            <tbody className={styles.tbody}>
                                <tr>
                                    <td>₹ 1500</td>
                                    <td>₹ 2000</td>
                                    <td>₹ 3000</td>
                                    <td>₹ 4000</td>
                                    <td>₹ 3850</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <li>Let's start by calculating the Net Winning
                        = (Total Withdrawal) + (Closing Account Balance) - (Total Deposit) - (Opening Account Balance )
                        = ₹ 3000 + ₹ 4000 - ₹ 2000 - ₹ 1500 = ₹ 3500</li>
                    <li>30% Tax on above Net Winnings = ₹ 1050</li>
                    <li>Of ₹ 1050, ₹ 900 tax already paid earlier</li>
                    <li>And so, the remaining tax to be deducted is ₹ 150</li>
                    <li>The remaining tax would be deducted from the Closing A/c Balance & carried forward to the new Financial Year: ₹ 4000 - ₹ 150 = ₹ 3850</li>
                </ul>
            </div>

            <div className={styles.content}>
                <h3>If my 'Net Winnings' go down during the year, can I get a refund of the TDS already deducted?</h3>
                <ul>
                    <li>Tax on Net Winnings at the time of the withdrawal that are deducted are paid to the income tax department for the credit of the player. Players can file their returns with the income tax department and claim refunds subject to the Player's overall tax liability. VyKa Games will be issuing the tax deduction certificates showing the tax deducted for the player. However, VyKa Games will not be able to refund the excess TDS from our end directly to any Player.</li>
                </ul>
            </div>

            <div className={styles.content}>
                <h3>If my 'Net Winnings' go down during the year, can I get a refund of the TDS already deducted?</h3>
                <ul>
                    <li>Tax on Net Winnings at the time of the withdrawal that are deducted are paid to the income tax department for the credit of the player. Players can file their returns with the income tax department and claim refunds subject to the Player's overall tax liability. VyKa Games will be issuing the tax deduction certificates showing the tax deducted for the player. However, VyKa Games will not be able to refund the excess TDS from our end directly to any Player.</li>
                </ul>
            </div>

            <div className={styles.content}>
                <h3>How can I get my Tax Certificate to file IT Returns?</h3>
                <ul>
                    <li>Tax Certificates will be issued within 1 month from the last date of filing TDS returns for every quarter.</li>
                </ul>
            </div>

            <div className={styles.content}>
                <h3>I play on VyKa Rummy and VyKa Ludo. How will this impact me?</h3>
                <ul>
                    <li>Players playing any games offered by VyKa Games, have a common wallet across games. So, for taxation, the entry fee paid for all games will be deducted from the winnings from all games. The balance amount will be considered for taxation at the rate of 30%.</li>
                </ul>
            </div>

            <div className={styles.content}>
                <h3>Why did the method of calculating Tax on Net Winnings change?</h3>
                <ul>
                <li>The previous method to calculate Net Winnings was (Total Winnings) - (Total Entry Fees).</li>
                <li>We have simplified the Net Winnings calculation. The revised method calculates Net Winnings as (Total Withdrawals) - (Total Deposits).</li>
                <li>This method of calculating Net Winnings will apply from 1st April 2023.
                    In case you have any queries, please contact our customer support team at grievance@vykagames.com.</li>
                    </ul>
            </div>
        </div>
        </>
    );
}

export default TDSOnWinning;
