import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from "./ludoLegalityLayout.module.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import lbanner from "../../assets/images/footer/lBanner.webp"
import ludoDetails from "../../config/ludoDetails.json"
import logo from "../../assets/images/logo.png"
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GiHamburgerMenu } from 'react-icons/gi';

const LudoLegalityLayout = () => {

    const [width, setWidth] = useState(0);
    const location=useLocation()
    const [anchorEl, setAnchorEl] = React.useState(null);

    useLayoutEffect(() => {
        function updateSize() {
            setWidth(window.innerWidth)
        }
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const navigate = useNavigate()

      const handleRouteClick = () => {
          navigate("/")
      }

     
      const open = Boolean(anchorEl);
      const handleClick = (e: any) => {
        setAnchorEl(e.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

    return (
        <div className={styles.lLayout_cont}>
            <div style={{ cursor: "pointer",position:'absolute',top:"1rem",left:"1rem" }} onClick={handleRouteClick} ><img className={styles.logo}  src={logo} alt="logo" />
            </div>
            
            
            <div className={styles.lImg_cont}>
                <LazyLoadImage className={styles.lBanner} src={lbanner} alt="banner"  />
                <div className={styles.hamburger}>
                <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(e) => handleClick(e)}
            style={{ cursor: "pointer",position:'absolute',top:"0.3rem",right:"0rem",color: "#fff"}}
          >
            <GiHamburgerMenu style={{ fontSize: "1.5rem" }} />
          </Button>
                <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}

            style={{ color: "#111" }}
          >
            {
                        ludoDetails?.map((item,i)=>{
                            return(
                                <MenuItem onClick={handleClose} style={{textWrap:"wrap",overflowX:"scroll"}}><Link  className={styles.lLink} to={item.path} style={{ color :` ${location.pathname === item?.path ?"#FFC148":"#fff" }`,textDecoration:"none",fontWeight:"600"}}>{item?.name}</Link></MenuItem>
                            )
                        })
            }
          </Menu>
          </div>
            </div>
            <div className={styles.lLayout_subcont}>
                
                <div className={styles.lSidebar_cont}>
                    {
                        ludoDetails?.map((item,i)=>{
                            return(
                                <Link className={styles.lLink} to={item.path} style={{ background :` ${location.pathname === item?.path ?"#242424":"#000000" }`}}>{item?.name}</Link>
                            )
                        })
                    }
                </div>

                <div className={styles.lContent_cont}>
                    <Outlet />
                </div>
            </div>


        </div>
    );
}

export default LudoLegalityLayout;
