import React from 'react';
import styles from "./products.module.scss"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import phImg from "../../assets/images/mobile.webp"
import ludoPhImg from "../../assets/images/ludoPh.webp"
import appStore from "../../assets/images/appStore.png"
import playStore from "../../assets/images/playStore.png"
import { Link } from 'react-router-dom';

const Products = () => {
    return (
        <section className={styles.prod_cont} id='products'>
            <header className={styles.prod_header}>PRODUCTS</header>
            <div className={styles.prod_subCont}>
                <div className={styles.vRummyImg_cont}>
                    <div className={`${styles.vRummyImg_subcont} ${styles.vRummyImg_cont1}`}>
                        <header className={styles.img_header}>Vyka Rummy</header>
                        <LazyLoadImage src={phImg} alt="phImg"  />
                    </div>

                    <div className={styles.vRummyCont_cont}>

                        {/* <div className={styles.download_btn}>

                            <div className={styles.inner_download_btn}>DOWNLOAD NOW</div>
                            <div className={styles.img_cont}>
                                <Link className={styles.applink} to="https://apps.apple.com/in/app/vyka-games/id6502700024" >
                                    <img className={styles.d_img} src={appStore} alt="appStore" />
                                </Link>
                                <Link className={styles.applink} to="https://gamingprod.s3.ap-south-1.amazonaws.com/apk/vykagames-V1.1.apk" >
                                <img className={styles.d_img} src={playStore} alt="playStore" />
                                </Link>
                            </div>

                        </div> */}

                        <div className={styles.vRummy_content}>
                            Vyka Rummy stands as a premier platform dedicated to providing players with an unparalleled gaming experience rooted in fairness, reliability, and exceptional customer service. Upholding a steadfast commitment to integrity, our platform operates under a stringent fair play policy, ensuring that every game is conducted transparently and ethically. With a focus on user satisfaction, we strive to cultivate an environment where players can engage in their favorite card game with confidence, knowing that they are part of a trustworthy community.
                        </div>
                    </div>
                </div>
                <div className={styles.vLudoImg_cont}>
                    <div className={`${styles.vLudoImg_subCont} ${styles.vLudoImg_cont1}`}>
                        <header className={styles.img_header}>Vyka Ludo</header>
                        <LazyLoadImage src={ludoPhImg} alt="ludoPhImg"  />
                    </div>
                    <div className={styles.vLudoCont_cont}>
                        {/* <div className={styles.download_btn}>

                            <div className={styles.inner_download_btn}>DOWNLOAD NOW</div>
                            <div className={styles.img_cont}>
                                <Link className={styles.applink} to="https://apps.apple.com/in/app/vyka-games/id6502700024" >
                                    <img className={styles.d_img} src={appStore} alt="appStore" />
                                </Link>
                                <Link className={styles.applink} to="https://gamingprod.s3.ap-south-1.amazonaws.com/apk/vykagames-V1.1.apk" >
                                <img className={styles.d_img} src={playStore} alt="playStore" />
                                </Link>

                            </div>

                        </div> */}
                        <div className={styles.vLudo_content}>
                            Vyka Ludo mirrors the commitment to excellence and integrity upheld by its counterpart, Vyka Rummy. Our platform is dedicated to providing players with a superior gaming experience characterized by fairness, reliability, and exceptional customer service. With a stringent adherence to a fair play policy, every match on Vyka Ludo is conducted transparently and ethically, ensuring an enjoyable and trustworthy gaming environment for all participants.
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.prodContent_cont}>
                <div className={styles.prodContent_subCont}>


                </div>
                <div className={styles.notifi_cont}>
                    <div className={styles.notifi}>Coming soon with more products and services.</div>
                </div>
            </div>
        </section>
    );
}

export default Products;
